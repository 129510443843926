.themes-v1 {


	a {
		text-decoration: none;
		margin-bottom: 30px;

		span {
			text-shadow: 0 3px 3px #222222c2;
			color: var(--white);
			font-size: 30px;
			text-align: center;
		}
	}


	.img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/tiles-spa.jpg);
	}

	.img2 {
		grid-area: img2;
		background-image: url(RESOURCE/img/hund.jpg);
	}

	.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/tiles-barrier.jpg);
	}

	.img4 {
		grid-area: img4;
		background-image: url(RESOURCE/img/theme-familien.jpg);
	}

	.img5 {
		grid-area: img5;
		background-image: url(RESOURCE/img/theme-historie.jpg);
	}

	.img6 {
		grid-area: img6;
		background-image: url(RESOURCE/img/tiles-kamin.jpg);
	}

	.img7 {
		grid-area: img7;
		background-image: url(RESOURCE/img/tiles-bathware.jpg);
	}

	.img8 {
		grid-area: img8;
		background-image: url(RESOURCE/img/theme-group.jpg);
	}

	.img9 {
		grid-area: img9;
		background-image: url(RESOURCE/img/tiles-charging.jpg);
	}


	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 10px;

		@media (max-width: 992px) {
			height: 35vh;
		}


		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 360px;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;

		@media (max-width:767px) {
			width: 100%;
		}
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
	}



	@media (min-width: 992px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;
		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;
		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(hover) {
			background-color: rgba(101, 153, 131, .4);
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-3--description-container {
			height: 15%
		}

		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0px 10px;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3:hover .theme-card-3--description-container {
			height: 100%;
			transition: 1s;
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 991px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 30px;

		}
	}
}