.search-page-layout-v3 {
	background-color: #f2f2f2;
	margin-top: var(--page-margin-top);
	padding-bottom: 90px !important;

	.container {
		@media (min-width: 1500px) {
			max-width: 1240px !important;
		}
	}


	.inner-banner {
		background-image: url(RESOURCE/img/search-banner.jpg);

		/*	&.ferienunterkunfte {
			background-image: url(RESOURCE/img/banner-ferienunterkunfte.jpg);
		}

		&.ferienwohnungen {
			background-image: url(RESOURCE/img/banner-ferienwohnungen.jpg);
		}

		&.ferienhauser {
			background-image: url(RESOURCE/img/banner-ferienhauser.jpg);
		}

		&.mit-hund {
			background-image: url(RESOURCE/img/banner-mit-hund.jpg);
		}

		&.mit-kamin {
			background-image: url(RESOURCE/img/banner-mit-kamin.jpg);
		}

		&.mit-sauna {
			background-image: url(RESOURCE/img/banner-mit-sauna.jpg);
		}

		&.langzeitmiete {
			background-image: url(RESOURCE/img/banner-langzeitmiete.jpg);
		}

		&.workation {
			background-image: url(RESOURCE/img/banner-workation.jpg);
		}

		&.monteuer {
			background-image: url(RESOURCE/img/banner-monteure.jpg);
		}*/
	}

	.results {
		display: block;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
		margin-top: 15px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

	.seo-main-wraper {
		.img-auto {
			width: 100%;
		}
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}